<template>
  <p :class="s.isTransparent">
    <slot name="text"></slot>
    <router-link to="/profile">profile settings</router-link>
  </p>
</template>

<script>
export default {
    name: 'MfaDisabled',
};
</script>

<style lang="postcss" module="s">
.isTransparent {
  font-size: var(--fs-s);
}
</style>
