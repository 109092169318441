
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Placement from 'Entities/publicPresenter/Placement';
import ModuleBlocker from 'UI/ModuleBlocker.vue';

import TransferContent from './components/TransferContent.vue';

type NoData = Record<string, never>;

interface Computed {
    placements: Placement[];
    hasAccountAccess: boolean;
    isMFADisabled: boolean;
}

export default Vue.extend<NoData, NoData, Computed>({
    name: 'TransferRoot',
    components: {
        TransferContent,
        ModuleBlocker,
    },
    computed: {
        ...mapGetters({
            placements: 'Placements/placements',
            isMFADisabled: 'Auth/isMFADisabled',
            activeAccountId: 'Accounts/activeAccountID',
        }),
        hasAccountAccess() {
            return this.$store.getters['Accounts/hasActiveAccountPolicy']('allocation');
        },
    },
});
