
import Vue from 'vue';
import { mapGetters } from 'vuex';

import HeaderSwitcher from 'Control/HeaderSwitcher.vue';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import PublicDataApi from 'Apis/PublicData';
import AssetsRequest from 'Entities/publicPresenter/AssetsRequest';
import Asset from 'Entities/publicPresenter/Asset';
import Account from 'Entities/privatePresenter/Account';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import Placement from 'Entities/publicPresenter/Placement';
import { RELEASED_PLATFORMS } from 'Store/modules/Placements';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import BlockchainRequest from 'Entities/publicPresenter/BlockchainRequest';
import Balance from 'Entities/privatePresenter/Balance';
import Icon from 'UI/Icon.vue';
import Checkbox from 'Control/Checkbox.vue';
import SelectFee from 'Control/SelectFee.vue';
import EmptyResult from 'Entities/walletExecutor/EmptyResult';
import WalletsApi from 'Apis/Wallets';
import TransferFeeRequestData from 'Entities/walletExecutor/TransferFeeRequestData';
import Button from 'Control/Button.vue';
import AssetQuotationRequest from 'Entities/publicPresenter/AssetQuotationRequest';
import MoneyInput from 'Control/MoneyInput.vue';
import { SET_PLACEMENT_NAME } from 'Store/v2/LinkPlacement';
import { SET_UI } from 'Store/v2/MultiTransfers';

interface Data {
    activeVariantIndex: number;
    activeCurrencyIndex: number;
    activeBlockchainIndex: number;
    assetsList: Asset[];
    blockchainsList: string[];
    activeBlockchainData: null | Blockchain;
    toPlacements: { name: string; quantity: number; feeSize: null | string; fees: Record<string, unknown> | EmptyResult | undefined; checked: boolean; }[];
    fromPlacementIndex: number;
    errors: Map<string, ('quantity' | 'fee')[]>;
    confirmAllocationData: {
        toPlacements: { name: string; quantity: number; feeSize: null | string; fees: Record<string, unknown> | EmptyResult | undefined; checked: boolean; }[];
        totalQuantity: number;
        totalFees: { asset: string; quantity: number; rate: number; }[];
        fromPlacement: string;
        asset: string;
        blockchain: string;
        variant: number;
        totalFeesQuotation: number;
        totalQuantityQuotation: number;
        quantityRate: number;
    };
}

interface Methods {
    setBlockchains: (data: number) => void;
    getActiveBlockchainData: () => void;
    getPlacementAssetBalance: (data: string) => null | Balance;
    getPlacementNativeAssetBalance: (data: string) => null | Balance;
    getTransferMinValue: (data: string) => number;
    setToPlacements: () => void;
    getQuantity: (data: string) => number;
    getChecked: (data: string) => boolean;
    setQuantity: (name: string, value: number) => void;
    setChecked: (data: string) => void;
    makeQuantityButtons: (data: string) => { title: string; callback: (args: any[]) => void }[];
    setQuantityMinValue: (data: string) => void;
    setQuantityPartOfFree: (mul: number, name: string) => void;
    getDisabledButtonsIndexes: (data: string) => number[];
    getFees: (data: string) => Promise<Record<string, unknown> | EmptyResult | undefined>;
    getFeesData: (data: string) => Record<string, unknown> | null;
    setFeeSize: (value: string, name: string) => void;
    setFees: (name: string, fees: Record<string, unknown> | EmptyResult | undefined) => void;
    getIsBlockchainNeeded: (data: string) => boolean;
    formAllocationData: () => void;
    validateForm: () => boolean;
    formErrors: () => void;
    hasFeeError: (data: string) => boolean;
    hasQuantityError: (data: string) => boolean;
    clearErrors: () => void;
    onModalClose: () => void;
    getPrecision: (data: string) => number;
    getIsPlacementAvailable: (placementName: string) => boolean;
    getIsPlacementLinked: (placementName: string) => boolean;
    linkPlacement: (placementName: string) => void;
}

interface Computed {
    activeAccount: Account;
    isThemeDark: boolean;
    activeAccountColor: string;
    assets: string[];
    blockchains: string[];
    placements: Placement[];
    activeAccountId: string;
    isNativeAssetBalanceNeeded: boolean;
    availableToPlacements: string[];
    checkedPlacementsQuantity: number;
    quotationAssetSymbol: string;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        SelectFee,
        AssetsBlockchainsDropdown,
        AccountColorMarker,
        HeaderSwitcher,
        Icon,
        Checkbox,
        Button,
        MoneyInput,
    },
    data() {
        return {
            activeVariantIndex: 0,
            activeCurrencyIndex: 0,
            activeBlockchainIndex: 0,
            assetsList: [],
            blockchainsList: [],
            activeBlockchainData: null,
            fromPlacementIndex: 0,
            toPlacements: [],
            errors: new Map(),
            confirmAllocationData: {
                toPlacements: [],
                totalQuantity: 0,
                totalFees: [],
                fromPlacement: '',
                asset: '',
                blockchain: '',
                variant: 0,
                totalFeesQuotation: 0,
                totalQuantityQuotation: 0,
                quantityRate: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
            activeAccountId: 'Accounts/activeAccountID',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        assets() {
            if (this.assetsList.length === 0) {
                return ['No Assets'];
            }
            return this.assetsList
                .filter(({ type }) => type !== 'fiat')
                .map(({ symbol }) => symbol);
        },
        blockchains() {
            if (this.blockchainsList.length === 0) {
                return ['No Networks'];
            }
            return this.blockchainsList;
        },
        placements() {
            return this.$store.state.Placements.placements.filter(({ type, name }) => ((type === 'crypto-spot'
                || type === 'crypto-futures')
                && RELEASED_PLATFORMS[name])
                || type === 'self'
                || type === 'otc-spot');
        },
        isNativeAssetBalanceNeeded() {
            return this.assets[this.activeCurrencyIndex] !== this.activeBlockchainData?.nativeAssetSymbol;
        },
        availableToPlacements() {
            const list = this.assetsList.filter(({ type }) => type !== 'fiat');
            if (!list[this.activeCurrencyIndex]) {
                return [];
            }

            if (this.activeVariantIndex === 0) {
                return list[this.activeCurrencyIndex].transferDetails
                    ?.reduce((result, { source, destination, blockchainName }) => {
                        if (source === this.placements[this.fromPlacementIndex].name && (blockchainName === this.blockchains[this.activeBlockchainIndex] || !blockchainName)) {
                            result.push(destination ?? '');
                        }
                        return result;
                    }, [] as string[]) ?? [];
            }
            return list[this.activeCurrencyIndex].transferDetails
                ?.reduce((result, { source, destination, blockchainName }) => {
                    if (destination === this.placements[this.fromPlacementIndex].name && (blockchainName === this.blockchains[this.activeBlockchainIndex] || !blockchainName)) {
                        result.push(source ?? '');
                    }
                    return result;
                }, [] as string[]) ?? [];
        },
        checkedPlacementsQuantity() {
            return this.toPlacements.reduce((accum, { checked }) => (checked ? accum + 1 : accum), 0);
        },
    },
    methods: {
        async setBlockchains(activeCurrencyIndex) {
            try {
                const tempBlockchainsSet = new Set<string>();
                const list = this.assetsList.filter(({ type }) => type !== 'fiat');
                if (list[activeCurrencyIndex].transferDetails) {
                    list[activeCurrencyIndex].transferDetails!.forEach(({ blockchainName }) => {
                        if (blockchainName) {
                            tempBlockchainsSet.add(blockchainName);
                        }
                    });
                }
                this.blockchainsList = Array.from(tempBlockchainsSet);
                this.activeBlockchainIndex = 0;
                await this.getActiveBlockchainData();
            } catch {
                // indexes error
            }
        },
        async getActiveBlockchainData() {
            if (this.blockchainsList.length > 0 && this.blockchainsList[this.activeBlockchainIndex]) {
                const { data: blockchain } = await PublicDataApi.publicGetBlockchain(new BlockchainRequest({
                    name: this.blockchainsList[this.activeBlockchainIndex],
                }));
                this.activeBlockchainData = blockchain;
            }
        },
        getPlacementAssetBalance(name) {
            return this.$store.state.Balances.balances.find(({ assetSymbol, blockchainName, placementName, accountId }) => assetSymbol === this.assets[this.activeCurrencyIndex]
                && (blockchainName === this.blockchains[this.activeBlockchainIndex] || !blockchainName)
                && placementName === name
                && accountId === this.activeAccountId) ?? null;
        },
        getPlacementNativeAssetBalance(name) {
            return this.$store.state.Balances.balances.find(({ assetSymbol, blockchainName, placementName, accountId }) => assetSymbol === this.activeBlockchainData?.nativeAssetSymbol
                && (blockchainName === this.blockchains[this.activeBlockchainIndex] || !blockchainName)
                && placementName === name
                && accountId === this.activeAccountId) ?? null;
        },
        getTransferMinValue(name) {
            try {
                const list = this.assetsList.filter(({ type }) => type !== 'fiat');

                if (this.activeVariantIndex === 0) {
                    return list[this.activeCurrencyIndex].transferDetails?.find((d) => d.source === this.placements[this.fromPlacementIndex].name
                        && d.destination === name
                        && (d.blockchainName === this.blockchains[this.activeBlockchainIndex] || !d.blockchainName))?.transferMinSize ?? 0;
                }
                return list[this.activeCurrencyIndex].transferDetails?.find((d) => d.destination === this.placements[this.fromPlacementIndex].name
                    && d.source === name
                    && (d.blockchainName === this.blockchains[this.activeBlockchainIndex] || !d.blockchainName))?.transferMinSize ?? 0;
            } catch {
                return 0;
            }
        },
        setToPlacements() {
            if (this.activeAccountId) {
                this.toPlacements = [];
                this.placements.forEach(async ({ name }) => {
                    try {
                        if (this.getIsBlockchainNeeded(name) && this.availableToPlacements.includes(name)) {
                            const fees = await this.getFees(name);
                            this.toPlacements.push({
                                name,
                                quantity: 0,
                                feeSize: null,
                                checked: false,
                                fees,
                            });
                        } else {
                            this.toPlacements.push({
                                name,
                                quantity: 0,
                                feeSize: null,
                                checked: false,
                                fees: undefined,
                            });
                        }
                    } catch {
                        this.toPlacements.push({
                            name,
                            quantity: 0,
                            feeSize: null,
                            checked: false,
                            fees: undefined,
                        });
                    }
                    const ref = this.$refs[`selectFee${name}`];
                    if (ref && ref[0]) {
                        ref[0].activeButtonValue = '';
                        ref[0].feeValue = 0;
                    }
                });
            }
        },
        getQuantity(name) {
            return this.toPlacements.find(({ name: n }) => n === name)?.quantity ?? 0;
        },
        getChecked(name) {
            return this.toPlacements.find(({ name: n }) => n === name)?.checked ?? false;
        },
        async setQuantity(name, value) {
            this.toPlacements = this.toPlacements.map((p) => {
                if (name === p.name) {
                    return {
                        ...p,
                        quantity: value,
                    };
                }
                return p;
            });
            const fees = await this.getFees(name);
            this.setFees(name, fees);
            if (this.errors.size > 0) {
                this.formErrors();
            }
        },
        setChecked(name) {
            if (!this.availableToPlacements.includes(name)
                || name === this.placements[this.fromPlacementIndex].name
                || !this.getIsPlacementLinked(name)
                || !this.getIsPlacementAvailable(name)) {
                return;
            }
            this.toPlacements = this.toPlacements.map((p) => {
                if (name.toLowerCase() === p.name.toLowerCase()) {
                    if (p.checked) {
                        const ref = this.$refs[`selectFee${p.name}`];
                        if (ref) {
                            ref[0].activeButtonValue = '';
                            ref[0].feeValue = 0;
                        }
                        return {
                            ...p,
                            quantity: 0,
                            feeSize: null,
                            checked: !p.checked,
                        };
                    }
                    return {
                        ...p,
                        checked: !p.checked,
                    };
                }
                return p;
            });
            if (this.errors.size > 0) {
                this.formErrors();
            }
        },
        makeQuantityButtons(placementName) {
            return [{
                title: 'Min',
                callback: () => {
                    this.setQuantityMinValue(placementName);
                },
            },
            {
                title: '25%',
                callback: () => {
                    this.setQuantityPartOfFree(0.25, placementName);
                },
            },
            {
                title: '50%',
                callback: () => {
                    this.setQuantityPartOfFree(0.5, placementName);
                },
            },
            {
                title: '75%',
                callback: () => {
                    this.setQuantityPartOfFree(0.75, placementName);
                },
            },
            {
                title: 'Max',
                callback: () => {
                    this.setQuantityPartOfFree(1, placementName);
                },
            }];
        },
        async setQuantityMinValue(placementName) {
            this.toPlacements = this.toPlacements.map((p) => {
                if (p.name === placementName) {
                    return {
                        ...p,
                        quantity: Number(this.getTransferMinValue(placementName).toFixed(this.getPrecision(placementName))),
                    };
                }
                return p;
            });
            const fees = await this.getFees(placementName);
            this.setFees(placementName, fees);
            if (this.errors.size > 0) {
                this.formErrors();
            }
        },
        async setQuantityPartOfFree(multiplier, placementName) {
            let currentBalance;
            if (this.activeVariantIndex === 0) {
                currentBalance = this.getPlacementAssetBalance(this.placements[this.fromPlacementIndex].name)?.free ?? 0;
            } else {
                currentBalance = this.getPlacementAssetBalance(placementName)?.free ?? 0;
            }
            this.toPlacements = this.toPlacements.map((p) => {
                if (p.name === placementName) {
                    return {
                        ...p,
                        quantity: Number((currentBalance * multiplier).toFixed(this.getPrecision(placementName))),
                    };
                }
                return p;
            });
            const fees = await this.getFees(placementName);
            this.setFees(placementName, fees);
            if (this.errors.size > 0) {
                this.formErrors();
            }
        },
        getDisabledButtonsIndexes(placementName) {
            let currentBalance;
            if (this.activeVariantIndex === 0) {
                currentBalance = this.getPlacementAssetBalance(this.placements[this.fromPlacementIndex].name)?.free ?? 0;
            } else {
                currentBalance = this.getPlacementAssetBalance(placementName)?.free ?? 0;
            }
            const transferMinSize = this.getTransferMinValue(placementName);
            if (currentBalance === 0) {
                return [1, 2, 3, 4];
            }
            if (transferMinSize > currentBalance) {
                return [1, 2, 3, 4];
            }
            if (transferMinSize > currentBalance * 0.75) {
                return [1, 2, 3];
            }
            if (transferMinSize > currentBalance * 0.5) {
                return [1, 2];
            }
            if (transferMinSize > currentBalance * 0.25) {
                return [1];
            }
            return [];
        },
        async getFees(placementName) {
            if (placementName === this.placements[this.fromPlacementIndex].name) {
                return undefined;
            }
            try {
                const { data: fees } = await WalletsApi.getTransferFees(new TransferFeeRequestData({
                    amount: this.toPlacements.find(({ name }) => name === placementName)?.quantity ?? 0,
                    assetSymbol: this.assets[this.activeCurrencyIndex],
                    blockchainName: this.blockchains[this.activeBlockchainIndex],
                    source: {
                        accountId: this.activeAccountId,
                        placementName: this.activeVariantIndex === 0 ? this.placements[this.fromPlacementIndex]?.name ?? '' : placementName,
                    },
                    destination: {
                        accountId: this.activeAccountId,
                        placementName: this.activeVariantIndex === 0 ? placementName : this.placements[this.fromPlacementIndex]?.name ?? '',
                    },
                }));
                if (fees instanceof EmptyResult) {
                    return fees;
                }

                const feeAsset = Object.keys(fees.low!)[0];
                return {
                    low: {
                        amount: fees.low![feeAsset],
                        assetSymbol: feeAsset.toUpperCase(),
                    },
                    medium: {
                        amount: fees.medium![feeAsset],
                        assetSymbol: feeAsset.toUpperCase(),
                    },
                    high: {
                        amount: fees.high![feeAsset],
                        assetSymbol: feeAsset.toUpperCase(),
                    },
                };
            } catch {
                return new EmptyResult({});
            }
        },
        getFeesData(placementName) {
            const fees = this.toPlacements.find(({ name }) => name === placementName)?.fees ?? null;
            if (fees instanceof EmptyResult) {
                return null;
            }
            return fees;
        },
        setFeeSize(value, placementName) {
            this.toPlacements = this.toPlacements.map((p) => {
                if (p.name === placementName) {
                    return {
                        ...p,
                        feeSize: value,
                    };
                }
                return p;
            });
            if (this.errors.size > 0) {
                this.formErrors();
            }
        },
        setFees(placementName, fees) {
            this.toPlacements = this.toPlacements.map((p) => {
                if (p.name === placementName) {
                    return {
                        ...p,
                        fees,
                    };
                }
                return p;
            });
        },
        getIsBlockchainNeeded(name) {
            const activeAsset = this.assetsList.filter(({ type }) => type !== 'fiat')[this.activeCurrencyIndex];
            if (!activeAsset || !activeAsset.transferDetails) {
                return true;
            }
            if (this.activeVariantIndex === 0) {
                const detail = activeAsset.transferDetails
                    .find(({ source, destination }) => source === this.placements[this.fromPlacementIndex].name && destination === name);
                if (!detail) {
                    return true;
                }
                return detail.blockchainName !== undefined;
            }
            const detail = activeAsset.transferDetails
                .find(({ source, destination }) => destination === this.placements[this.fromPlacementIndex].name && source === name);
            if (!detail) {
                return true;
            }
            return detail.blockchainName !== undefined;
        },
        async formAllocationData() {
            if (!this.validateForm()) {
                this.formErrors();
                return;
            }

            this.confirmAllocationData.asset = this.assets[this.activeCurrencyIndex];
            this.confirmAllocationData.blockchain = this.blockchains[this.activeBlockchainIndex];
            this.confirmAllocationData.variant = this.activeVariantIndex;
            this.confirmAllocationData.fromPlacement = this.placements[this.fromPlacementIndex].name;
            this.confirmAllocationData.toPlacements = this.toPlacements.filter(({ checked }) => checked);
            this.confirmAllocationData.totalQuantity = this.confirmAllocationData.toPlacements.reduce((accum, { quantity }) => accum + quantity, 0);

            const feesArray: { asset: string; quantity: number; rate: number; }[] = [];
            this.confirmAllocationData.toPlacements.forEach((p) => {
                if (p.fees && p.feeSize && !(p.fees instanceof EmptyResult)) {
                    const index = feesArray.findIndex(({ asset }) => asset === (p.fees as any).low.assetSymbol);
                    if (index !== -1) {
                        feesArray[index].quantity += (p.fees[p.feeSize] as any).amount;
                    } else {
                        feesArray.push({
                            asset: (p.fees[p.feeSize] as any).assetSymbol,
                            quantity: (p.fees[p.feeSize] as any).amount,
                            rate: 0,
                        });
                    }
                }
            });
            this.confirmAllocationData.totalFees = feesArray;

            this.$store.commit(SET_LOADING_ON(undefined));
            try {
                const { data: quotation } = await PublicDataApi.publicGetAssetQuotation(new AssetQuotationRequest({
                    symbol: this.confirmAllocationData.asset,
                    quotationSymbol: this.quotationAssetSymbol,
                }));
                this.confirmAllocationData.totalQuantityQuotation = this.confirmAllocationData.totalQuantity * quotation.rate;
                this.confirmAllocationData.quantityRate = quotation.rate;
            } catch {
                this.confirmAllocationData.totalQuantityQuotation = 0;
            }

            let totalFeesQuoted = 0;
            for (let i = 0; i < this.confirmAllocationData.totalFees.length; i += 1) {
                // eslint-disable-next-line no-await-in-loop
                const { data: quotation } = await PublicDataApi.publicGetAssetQuotation(new AssetQuotationRequest({
                    symbol: this.confirmAllocationData.totalFees[i].asset,
                    quotationSymbol: this.quotationAssetSymbol,
                }));
                this.confirmAllocationData.totalFees[i].rate = quotation.rate;
                totalFeesQuoted += this.confirmAllocationData.totalFees[i].quantity * quotation.rate;
            }
            this.confirmAllocationData.totalFeesQuotation = totalFeesQuoted;

            this.$emit('confirm', this.confirmAllocationData);
            setTimeout(() => {
                this.$modal.hide('multitransfersModal');
                this.$modal.show('multitransfersConfirmModal');
                this.$store.commit(SET_LOADING_OFF(undefined));
            }, 100);
        },
        validateForm() {
            // check fees errors
            const filteredPlacements = this.toPlacements.filter(({ checked }) => checked);
            const hasFeesErrors = filteredPlacements.some(({ name, feeSize }) => this.getIsBlockchainNeeded(name) && !feeSize);
            if (hasFeesErrors) {
                return false;
            }

            // check one to many balances errors
            if (this.activeVariantIndex === 0) {
                const balance = this.getPlacementAssetBalance(this.placements[this.fromPlacementIndex].name)?.free ?? 0;
                const nativeBalance = this.getPlacementNativeAssetBalance(this.placements[this.fromPlacementIndex].name)?.free ?? 0;
                let summaryQuantity = 0;
                let summaryNativeQuantity = 0;
                let hasMinValueError = false;
                filteredPlacements.forEach(({ name, quantity, feeSize, fees }) => {
                    const transferMinSize = this.getTransferMinValue(name);
                    summaryQuantity += quantity;
                    if (quantity < transferMinSize) {
                        hasMinValueError = true;
                    }
                    if (this.getIsBlockchainNeeded(name)) {
                        const isFeeAssetAndQuantityAssetDifferent = fees![feeSize!].assetSymbol !== this.assets[this.activeCurrencyIndex];
                        if (isFeeAssetAndQuantityAssetDifferent) {
                            summaryNativeQuantity += fees![feeSize!].amount;
                        } else if (quantity < fees![feeSize!].amount) {
                            hasMinValueError = true;
                        }
                    }
                });
                if (hasMinValueError) {
                    return false;
                }
                return summaryQuantity <= balance && summaryNativeQuantity <= nativeBalance;
            }

            // check many to one balances errors
            const hasQuantityErrors = filteredPlacements.some(({ name, quantity, feeSize, fees }) => {
                const transferMinSize = this.getTransferMinValue(name);
                const balance = this.getPlacementAssetBalance(name);
                const nativeBalance = this.getPlacementNativeAssetBalance(name);
                if (this.getIsBlockchainNeeded(name)) {
                    const isFeeAssetAndQuantityAssetDifferent = fees![feeSize!].assetSymbol !== this.assets[this.activeCurrencyIndex];
                    if (isFeeAssetAndQuantityAssetDifferent) {
                        return !(balance
                            && balance!.free >= quantity
                            && nativeBalance
                            && nativeBalance!.free >= fees![feeSize!].amount
                            && quantity >= transferMinSize);
                    }
                    return !(balance
                        && balance!.free >= quantity
                        && quantity >= fees![feeSize!].amount
                        && quantity >= transferMinSize);
                }
                return !(balance
                    && balance!.free >= quantity
                    && quantity >= transferMinSize);
            });
            return !hasQuantityErrors;
        },
        async formErrors() {
            this.errors = new Map();
            const filteredPlacements = this.toPlacements.filter(({ checked }) => checked);
            filteredPlacements.forEach(({ name, feeSize }) => {
                if (this.getIsBlockchainNeeded(name) && !feeSize) {
                    this.errors.set(name, ['fee']);
                    this.$store.dispatch('Notificator/showErrorNotification', `Fee size is not selected for ${name}`);
                }
            });

            if (this.activeVariantIndex === 0) {
                const balance = this.getPlacementAssetBalance(this.placements[this.fromPlacementIndex].name)?.free ?? 0;
                const nativeBalance = this.getPlacementNativeAssetBalance(this.placements[this.fromPlacementIndex].name)?.free ?? 0;
                let summaryQuantity = 0;
                let summaryNativeQuantity = 0;
                const minValueErrors = new Set<string>();
                filteredPlacements.forEach(({ name, quantity, feeSize, fees }) => {
                    const transferMinSize = this.getTransferMinValue(name);
                    summaryQuantity += quantity;
                    if (quantity < transferMinSize) {
                        minValueErrors.add(name);
                    }
                    if (this.getIsBlockchainNeeded(name)) {
                        const isFeeAssetAndQuantityAssetDifferent = fees![feeSize!].assetSymbol !== this.assets[this.activeCurrencyIndex];
                        if (isFeeAssetAndQuantityAssetDifferent) {
                            summaryNativeQuantity += fees![feeSize!].amount;
                        } else if (quantity < fees![feeSize!].amount) {
                            minValueErrors.add(name);
                        }
                    }
                });
                if (!(summaryQuantity <= balance && summaryNativeQuantity <= nativeBalance)) {
                    filteredPlacements.forEach(({ name }) => {
                        if (this.errors.has(name)) {
                            this.errors.set(name, ['fee', 'quantity']);
                        } else {
                            this.errors.set(name, ['quantity']);
                        }
                    });
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds');
                } else {
                    Array.from(minValueErrors).forEach((name) => {
                        if (this.errors.has(name)) {
                            this.errors.set(name, ['fee', 'quantity']);
                        } else {
                            this.errors.set(name, ['quantity']);
                        }
                    });
                    await this.$store.dispatch('Notificator/showErrorNotification', `Quantity is lower than transfer minimal size for ${Array.from(minValueErrors).join(', ')}`);
                }
                return;
            }

            filteredPlacements.forEach(({ name, quantity, feeSize, fees }) => {
                const transferMinSize = this.getTransferMinValue(name);
                const nativeBalance = this.getPlacementNativeAssetBalance(this.activeVariantIndex === 0 ? this.placements[this.fromPlacementIndex].name : name);
                const balance = this.getPlacementAssetBalance(this.activeVariantIndex === 0 ? this.placements[this.fromPlacementIndex].name : name);
                if (this.getIsBlockchainNeeded(name)) {
                    const isFeeAssetAndQuantityAssetDifferent = fees![feeSize!] && fees![feeSize!].assetSymbol !== this.assets[this.activeCurrencyIndex];
                    if (isFeeAssetAndQuantityAssetDifferent) {
                        if (!(balance
                            && balance!.free >= quantity
                            && nativeBalance
                            && nativeBalance!.free >= fees![feeSize!].amount
                            && quantity >= transferMinSize)) {
                            if (this.errors.has(name)) {
                                this.errors.set(name, ['fee', 'quantity']);
                            } else {
                                this.errors.set(name, ['quantity']);
                            }
                            this.$store.dispatch('Notificator/showErrorNotification', `Not enough funds or transfer is lower than transfer minimal size for ${name}`);
                        }
                    } else if (!(balance
                        && balance!.free >= quantity
                        && quantity >= fees![feeSize!].amount
                        && quantity >= transferMinSize)) {
                        if (this.errors.has(name)) {
                            this.errors.set(name, ['fee', 'quantity']);
                        } else {
                            this.errors.set(name, ['quantity']);
                        }
                        this.$store.dispatch('Notificator/showErrorNotification', `Not enough funds or transfer is lower than transfer minimal size for ${name}`);
                    }
                } else if (!(balance
                    && balance!.free >= quantity
                    && quantity >= transferMinSize)) {
                    if (this.errors.has(name)) {
                        this.errors.set(name, ['fee', 'quantity']);
                    } else {
                        this.errors.set(name, ['quantity']);
                    }
                    this.$store.dispatch('Notificator/showErrorNotification', `Not enough funds or transfer is lower than transfer minimal size for ${name}`);
                }
            });
        },
        hasFeeError(placementName) {
            if (!this.errors.has(placementName)) {
                return false;
            }
            return this.errors.get(placementName)!.includes('fee');
        },
        hasQuantityError(placementName) {
            if (!this.errors.has(placementName)) {
                return false;
            }
            return this.errors.get(placementName)!.includes('quantity');
        },
        clearErrors() {
            this.errors = new Map();
        },
        onModalClose() {
            this.clearErrors();
        },
        getPrecision(placementName) {
            if (this.activeVariantIndex === 0) {
                if (this.placements[this.fromPlacementIndex].name.toLowerCase() === 'okx') {
                    return 3;
                }
                return 8;
            }
            if (placementName.toLowerCase() === 'okx') {
                return 3;
            }
            return 8;
        },
        getIsPlacementAvailable(placementName) {
            return this.$store.state.Placements.maintenanceStatuses.get(placementName) || placementName === 'Single Broker';
        },
        getIsPlacementLinked(placementName) {
            return this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](placementName) || placementName === 'Single Broker';
        },
        async linkPlacement(placementName) {
            const placement = this.$store.state.Placements.placements.find((p) => p.name.toLowerCase() === placementName.toLowerCase());
            if (placement && placement.needExternalKyc) {
                this.$store.commit(SET_PLACEMENT_NAME(placementName));
                this.$modal.show('confirmLinkModal');
            } else {
                await this.$store.dispatch('Accounts/linkPlacement', placementName);
            }
        },
    },
    async mounted() {
        try {
            this.$store.commit(SET_LOADING_ON(undefined));
            this.clearErrors();
            const { data: assets } = await PublicDataApi.publicGetAssets(new AssetsRequest({
                perPage: 256,
                page: 1,
                transferable: true,
                includeTransferDetails: true,
            }));
            this.assetsList = assets;
            await this.setBlockchains(this.activeCurrencyIndex);
            this.setToPlacements();
        } catch (error) {
            if (error instanceof ApiError) {
                await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error loading assets data');
            }
        } finally {
            this.$store.commit(SET_LOADING_OFF(undefined));
        }
    },
    watch: {
        async activeCurrencyIndex(value) {
            this.clearErrors();
            await this.setBlockchains(value);
            this.setToPlacements();
            this.$store.commit(SET_UI({
                activeCurrencyIndex: value,
            }));
        },
        async activeBlockchainIndex(value) {
            this.clearErrors();
            await this.getActiveBlockchainData();
            this.setToPlacements();
            this.$store.commit(SET_UI({
                activeBlockchainIndex: value,
            }));
        },
        placements(_, oldValue) {
            if (!oldValue || oldValue.length === 0) {
                this.setToPlacements();
            }
        },
        activeAccountId(_, oldValue) {
            if (!oldValue) {
                this.setToPlacements();
            }
        },
        fromPlacementIndex(value) {
            this.clearErrors();
            this.setToPlacements();
            this.$store.commit(SET_UI({
                fromPlacementIndex: value,
            }));
        },
        activeVariantIndex(value) {
            this.clearErrors();
            this.setToPlacements();
            this.$store.commit(SET_UI({
                activeVariantIndex: value,
            }));
        },
        toPlacements(value) {
            const names = new Set<string>();
            const newValue = value.filter(({ name }) => {
                if (names.has(name)) {
                    return false;
                }
                names.add(name);
                return true;
            });
            if (newValue.length !== value.length) {
                this.toPlacements = newValue;
            }
            this.$store.commit(SET_UI({
                toPlacements: value,
            }));
        },
    },
});
