// This file was autogenerated. Please do not change.
export interface IAssetRequest {
    blockchainId?: number;
    blockchainName?: string;
    id?: number;
    placementId?: number;
    placementName?: string;
    symbol?: string;
}

export default class AssetRequest {
    readonly _blockchainId: number | undefined;

    /**
     * Description: Blockchain platform ID
     * Example: 3
     */
    get blockchainId(): number | undefined {
        return this._blockchainId;
    }

    readonly _blockchainName: string | undefined;

    /** Description: Common blockchain name, e.g. BTC, ETH, etc. */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _id: number | undefined;

    /**
     * Description: Asset platform ID
     * Example: 1
     */
    get id(): number | undefined {
        return this._id;
    }

    readonly _placementId: number | undefined;

    /**
     * Description: Placement platform ID
     * Example: 2
     */
    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Description: Placement platform Name
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _symbol: string | undefined;

    /**
     * Description: Asset symbol
     * Example: BTC
     */
    get symbol(): string | undefined {
        return this._symbol;
    }

    constructor(props: IAssetRequest) {
        if (typeof props.blockchainId === 'number') {
            this._blockchainId = props.blockchainId;
        }
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.id === 'number') {
            this._id = props.id;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.symbol === 'string') {
            this._symbol = props.symbol.trim();
        }
    }

    serialize(): IAssetRequest {
        const data: IAssetRequest = {
        };
        if (typeof this._blockchainId !== 'undefined') {
            data.blockchainId = this._blockchainId;
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._symbol !== 'undefined') {
            data.symbol = this._symbol;
        }
        return data;
    }

    toJSON(): IAssetRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            blockchainId: !this._blockchainId ? true : typeof this._blockchainId === 'number',
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            id: !this._id ? true : typeof this._id === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            symbol: !this._symbol ? true : typeof this._symbol === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
