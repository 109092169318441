<template>
    <div :class="s.column">
        <div :class="s.row">
            <span v-if="activeCurrencySymbol !== 'undefined'" :class="[s.bigText, s.gray]">Available: <span :class="[s.bigText, s.gray]">{{ total }}</span> {{ activeCurrencySymbol }}</span>
            <span v-else :class="[s.bigText, s.gray]">N/A</span>
        </div>
        <div :class="[s.row, s.gap]">
            <span :class="[s.smallText, s.green]">Free: {{ free }}</span>
            <span :class="[s.smallText, s.gray]">●</span>
            <span :class="[s.smallText, s.red]">Hold: {{ hold }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TransferPlacementBalance',
    props: {
        balanceData: {
            type: Object,
            default: () => null,
        },
        activeCurrencySymbol: {
            type: String,
            default: null,
        },
    },
    computed: {
        balance() {
            return this.balanceData ? this.balanceData : {};
        },
        total() {
            return this.balance && this.balance.total ? (+this.balance.total).toFixed(8).noExponents() : 0;
        },
        free() {
            return this.balance && this.balance.free ? (+this.balance.free).toFixed(8).noExponents() : 0;
        },
        hold() {
            if (!this.balance) {
                return 0;
            }
            const hold = this.balance.hold || 0;
            const positionsHold = this.balance.positionsHold || 0;
            return (Number(hold) + Number(positionsHold)).toFixed(8).noExponents();
        },
    },
};
</script>

<style lang="postcss" module="s">
.column {
    display: flex;
    flex-direction: column;
    row-gap: var(--m-s);
    & .row {
        display: flex;
        align-items: center;
        &.gap {
            column-gap: var(--m-xs);
        }
        & .bigText {
            font-weight: var(--fw-semibold);
            font-size: var(--fs-xs);
            line-height: var(--fs-xs);
            &.gray {
                color: var(--cl-gray);
            }
            &.violet {
                color: var(--cl-violet);
            }
        }
        & .smallText {
            font-weight: var(--fw-bold);
            font-size: var(--fs-xxs);
            line-height: var(--fs-xxs);
            &.green {
                color: var(--cl-green-strong);
            }
            &.red {
                color: var(--cl-red-strong);
            }
            &.gray {
                color: var(--cl-gray);
            }
        }
    }
}
</style>
