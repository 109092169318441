<template>
    <td :class="[theme.table.alignCenter, { [s.block]: isBlock }]">
        <div v-if="isAccountsTransfer">
            <Guid
                :guid="record.toAccountId"
                is-short
                v-if="needGuid"
                copied-value-name="Account ID"
            />
            <div
                :class="s.tag"
                v-else
            >
                <AccountColorMarker
                    :color="getAccountColorById(record.toAccountId)"
                />
                <span>
                    {{ getAccountNameById(record.toAccountId) }}
                </span>
            </div>
        </div>
        <div
            :class="[theme.transferhistory.blackText, s.row]"
            v-else-if="record.type !== RecordsTypes['WITHDRAW']"
        >
            <PlacementIcon
                :placement="record.toPlacementName"
                :size="labelIconSize"
            />
            {{ record.toPlacementName.toUpperCase() }}
        </div>
        <div
            :class="[s.userRow, theme.transferhistory.blackText]"
            v-else
        >
            <Icon
                icon="table_user"
                :class="s.userIcon"
            />
            <span>
                User
            </span>
        </div>
    </td>
</template>

<script>
import { mapGetters } from 'vuex';

import { RecordsTypes } from 'Models/transfersHistory';
import theme from 'Theme';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import Guid from 'UI/Guid.vue';
import Icon from 'UI/Icon.vue';

export default {
    name: 'RecordFrom',
    components: {
        Icon,
        AccountColorMarker,
        Guid,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
        isAccountsTransfer: {
            type: Boolean,
            default: false,
        },
        labelIconSize: {
            type: Number,
            default: 14,
        },
        labelTextSizeClass: {
            type: String,
            default: 'is-s',
        },
        isLabelTextTransparent: {
            type: Boolean,
            default: true,
        },
        isBlock: {
            type: Boolean,
            default: false,
        },
        needGuid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            RecordsTypes,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
    },
    methods: {
        getAccountNameById(accountId) {
            return this.$store.state.Accounts.accounts.find(({ id }) => id === accountId)?.name ?? accountId;
        },
        getAccountColorById(accountId) {
            const account = this.$store.state.Accounts.accounts.find(({ id }) => id === accountId);
            if (!account || !account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return account.color;
        },
    },
};
</script>

<style lang="postcss" module="s">
.row {
    display: flex;
    align-items: center;
    column-gap: var(--m-s);
    justify-content: center;
}
.userRow {
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
    justify-content: center;
}
.center {
    margin: auto;
}
.block {
    display: block;
    border-bottom: none !important;
}
.tag {
    background-color: var(--cl-gray-light);
    padding: var(--m-xs) var(--m-m);
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
    border-radius: 100px;
    margin: auto;
    text-align: center;
    font-style: normal;
    font-weight: var(--fw-regular);
    font-size: var(--fs-m);
    line-height: var(--fs-m);
    color: var(--cl-gray);
    flex-shrink: 0;
    justify-content: center;
    width: max-content;
}
.userIcon {
    width: 18px;
    height: 18px;
    color: var(--cl-black);
}
</style>
