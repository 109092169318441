<template>
    <div class="module" :class="s.relative">
        <ModuleBlocker v-if="isModuleBlocked" />
        <ModuleBlocker v-else-if="isMFADisabled && activeAccountId !== 'DEMOACC'" need-set-up-t-f-a />
        <BlockHeader
            title="Funding"
            class="draggable"
        >
            <HeaderSwitcher
                first-variant-text="Deposit"
                second-variant-text="Withdraw"
                :active-index="activePage"
                @change="setActivePage($event)"
            />
        </BlockHeader>
        <Withdraw
            v-if="isWithdrawTabActive"
            :is-disabled="isModuleBlocked"
            :class="s.content"
            :preseted-quantity="deposit.quantity"
            :preseted-address-index="deposit.addressIndex"
            :preseted-requisite-index="deposit.requisiteIndex"
            :preseted-currency-index="deposit.currencyIndex"
            :preseted-blockchain-index="deposit.blockchainIndex"
            @change-quantity="withdraw.quantity = $event"
            @change-blockchain="withdraw.blockchainIndex = $event"
            @change-currency="withdraw.currencyIndex = $event"
            @change-address="withdraw.addressIndex = $event"
            @change-requisite="withdraw.requisiteIndex = $event"
        />
        <Deposit
            v-else-if="isDepositTabActive"
            :is-disabled="isModuleBlocked"
            :class="s.content"
            :preseted-quantity="withdraw.quantity"
            :preseted-address-index="withdraw.addressIndex"
            :preseted-requisite-index="withdraw.requisiteIndex"
            :preseted-currency-index="withdraw.currencyIndex"
            :preseted-blockchain-index="withdraw.blockchainIndex"
            @change-quantity="deposit.quantity = $event"
            @change-blockchain="deposit.blockchainIndex = $event"
            @change-currency="deposit.currencyIndex = $event"
            @change-address="deposit.addressIndex = $event"
            @change-requisite="deposit.requisiteIndex = $event"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { AccountManagementPolicies } from 'Models/accounts';
import BlockHeader from 'UI/BlockHeader.vue';
import ModuleBlocker from 'UI/ModuleBlocker.vue';
import HeaderSwitcher from 'Control/HeaderSwitcher.vue';

import Withdraw from './components/Withdraw/Withdraw.vue';
import Deposit from './components/Deposit/Deposit.vue';

export default {
    name: 'Payment',
    components: {
        HeaderSwitcher,
        Withdraw,
        Deposit,
        BlockHeader,
        ModuleBlocker,
    },
    data() {
        return {
            activePage: 0,
            deposit: {
                currencyIndex: undefined,
                blockchainIndex: undefined,
                addressIndex: undefined,
                requisiteIndex: undefined,
                quantity: undefined,
            },
            withdraw: {
                currencyIndex: undefined,
                blockchainIndex: undefined,
                addressIndex: undefined,
                requisiteIndex: undefined,
                quantity: undefined,
            },
        };
    },
    computed: {
        ...mapGetters({
            isMFADisabled: 'Auth/isMFADisabled',
            activeAccountId: 'Accounts/activeAccountID',
        }),
        isWithdrawTabActive() {
            return this.activePage === 1;
        },
        isDepositTabActive() {
            return this.activePage === 0;
        },
        activeTabAccountAccess() {
            if (this.isWithdrawTabActive) {
                return AccountManagementPolicies.FINANCE;
            } if (this.isDepositTabActive) {
                return AccountManagementPolicies.FINANCE;
            }
            return null;
        },
        hasAccountAccess() {
            return this.$store.getters['Accounts/hasActiveAccountPolicy'](this.activeTabAccountAccess);
        },
        isPlacementBlockModule() {
            if (this.$store.getters['Placements/platformPlacement']) {
                if (this.isWithdrawTabActive) {
                    return this.$store.getters['Placements/platformPlacement'].isWithdrawalBlocked;
                } if (this.isDepositTabActive) {
                    return this.$store.getters['Placements/platformPlacement'].isDepositBlocked;
                }
                return false;
            }
            return false;
        },
        isModuleBlocked() {
            return !this.hasAccountAccess || this.isPlacementBlockModule;
        },
    },
    methods: {
        setActivePage(index) {
            this.activePage = index;
        },
    },
    mounted() {
        this.activePage = 0;
    },
};
</script>

<style lang="postcss" module="s">
.content {
    position: relative;
    width: 100%;
    flex-grow: 1;
    padding: var(--m-xl) var(--m-m);
}
.relative {
    position: relative;
}
</style>
