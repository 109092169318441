<template>
    <div :class="s.container">
        <div :class="s.column">
            <ListSelectBox
                @switch="switchExchanges(true, $event)"
                :items="items"
                :val="firstVal"
                title="From"
                v-model="activePlacementFromIndex"
                :isSorted="isSorted"
                :disabled-item-id="bannedFromItem.id"
                need-check-is-enabled
            >
                <template #item="slotProps">
                    <div :class="s.col">
                        <span :class="s.row">
                            <PlacementIcon :placement="slotProps.itemData.name" :size="14" />
                            <span>
                                {{ slotProps.itemData.name }}
                            </span>
                        </span>
                        <span :class="s.balanceText">
                            ≈{{ quotationAssetCharacter }}{{ getPlacementBalances(slotProps.itemData.name).toFixed(precision).getSeparatedDigits() }}
                        </span>
                    </div>
                </template>
            </ListSelectBox>
            <slot name="balance-from"></slot>
        </div>
        <div :class="s.arrowContainer">
            <Icon :class="s.icon" :icon="isThemeDark ? 'arrow_right_big_dark' : 'arrow_right_big'" />
        </div>
        <div :class="s.column">
            <ListSelectBox
                @switch="switchExchanges(false, $event)"
                :items="items"
                :val="secondVal"
                title="To"
                v-model="activePlacementToIndex"
                :isSorted="isSorted"
                :disabled-item-id="bannedToItem.id"
                need-check-is-enabled
            >
                <template #item="slotProps">
                    <div :class="s.col">
                        <div :class="s.row">
                            <PlacementIcon :placement="slotProps.itemData.name" :size="14" />
                            <span>
                                {{ slotProps.itemData.name }}
                            </span>
                        </div>
                        <span :class="s.balanceText">
                            ≈{{ quotationAssetCharacter }}{{ getPlacementBalances(slotProps.itemData.name).toFixed(precision).getSeparatedDigits() }}
                        </span>
                    </div>
                </template>
            </ListSelectBox>
            <slot name="balance-to"></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import sortPlacementList from 'Mixins/sortPlacementList';
import Icon from 'UI/Icon.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import ListSelectBox from 'Common/ListSelectBox.vue';

export default {
    name: 'Transfer.Exchanges',
    mixins: [sortPlacementList],
    components: { Icon, ListSelectBox },
    props: {
        fromExchangeIndex: {
            type: Number,
            required: true,
        },
        toExchangeIndex: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            bannedFromItem: { id: -1 },
            bannedToItem: { id: -1 },

            firstVal: 0,
            secondVal: 1,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
            balances: 'Balances/GET_BALANCES',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            isThemeDark: 'isThemeDark',
        }),
        activePlacementFromIndex: {
            get() {
                return this.fromExchangeIndex;
            },
            set(canBeSortedIndex) {
                const { id, index } = canBeSortedIndex;
                const { initialIndex } = this.items[index];
                this.bannedToItem = this.items[index];
                this.$emit('update-from', { index: initialIndex ?? index, id });
            },
        },
        activePlacementToIndex: {
            get() {
                return this.toExchangeIndex;
            },
            set(canBeSortedIndex) {
                const { id, index } = canBeSortedIndex;
                const { initialIndex } = this.items[index];
                this.bannedFromItem = this.items[index];
                this.$emit('update-to', { index: initialIndex ?? index, id });
            },
        },
        precision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
    },
    methods: {
        switchExchanges(flag, e) {
            if (flag) {
                const bannedToIndex = this.items.findIndex((i) => i.id === this.bannedToItem.id);
                this.firstVal = e.index;
                this.secondVal = bannedToIndex;
                this.bannedFromItem = this.items[this.secondVal];
                this.bannedToItem = this.items[this.firstVal];
            } else {
                const bannedFromIndex = this.items.findIndex((i) => i.id === this.bannedFromItem.id);
                this.secondVal = e.index;
                this.firstVal = bannedFromIndex;
                this.bannedFromItem = this.items[this.secondVal];
                this.bannedToItem = this.items[this.firstVal];
            }
            this.$emit('update-to', { index: this.items[this.secondVal], id: this.items[this.secondVal].name });
            this.$emit('update-from', { index: this.items[this.firstVal], id: this.items[this.firstVal].name });
        },
        getPlacementBalances(name) {
            return this.balances.reduce((accum, { accountId, placementName, quotationAssetSymbols, quotations, total }) => {
                if (placementName !== name || accountId !== this.activeAccountId) {
                    return accum;
                }
                const quotationAssetSymbolIndex = quotationAssetSymbols
                    ? quotationAssetSymbols.findIndex((symbol) => symbol === this.quotationAssetSymbol)
                    : -1;
                if (quotationAssetSymbolIndex === -1) {
                    return accum;
                }
                return accum + (Number(quotations[quotationAssetSymbolIndex]) * total);
            }, 0);
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: flex;
    justify-content: space-between;
    height: 217px;
    & .column {
        height: 231px;
        display: grid;
        width: 100%;
        grid-template-columns: 100%;
        grid-template-rows: 166px 44px;
        grid-row-gap: 11px;
    }
    & .arrowContainer {
        min-width: 34px;
        padding: 0 var(--m-xs);
    }
    & .icon {
        width: 26px;
        height: 26px;
        margin-top: 70px;
    }
    & .row {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: var(--m-xs);
    }
    & .col {
        display: flex;
        flex-direction: column;
        row-gap: var(--m-xs);
    }
    & .balanceText {
        font-weight: var(--fw-semibold);
        font-size: var(--fs-m);
        line-height: var(--fs-m);
        color: var(--cl-gray);
        margin-left: calc(14px + var(--m-xs));
    }
}
</style>
