<template>
    <div class="draggable" :class="[s.container, theme.statusrow.statusrow]">
        <div :class="s.mainColumn">
            <div :class="s.accountNameRow">
                <AccountColorMarker
                    :color="accountColor"
                    :size="12"
                />
                <span :class="s.blackText">
                    #{{ activeAccount ? activeAccount.name : '...' }}
                </span>
            </div>
        </div>
        <div :class="s.row">
            <div :class="theme.statusrow.contentColumn">
                <div>
                    <p :class="theme.statusrow.subheaderText">
                        Wallet Owner ID
                    </p>
                    <p :class="s.blackText">
                        {{ activeAccountOwnerId }}
                    </p>
                </div>
            </div>
            <div :class="theme.statusrow.contentColumn">
                <div>
                    <p :class="theme.statusrow.subheaderText">
                        Owner Tier
                    </p>
                    <p :class="s.blackText">
                        Beta
                    </p>
                </div>
            </div>
            <div :class="theme.statusrow.contentColumn">
                <div>
                    <p :class="theme.statusrow.subheaderText">
                        Withdrawn
                    </p>
                    <p :class="s.blackText">
                        N/A
                    </p>
                </div>
            </div>
            <div :class="theme.statusrow.contentColumn">
                <div>
                    <p :class="theme.statusrow.subheaderText">
                        Remaining
                    </p>
                    <p :class="s.blackText">
                        N/A
                    </p>
                </div>
            </div>
            <div :class="theme.statusrow.contentColumn">
                <div>
                    <p :class="theme.statusrow.subheaderText">
                        Limit
                    </p>
                    <p :class="s.blackText">
                        N/A
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import theme from 'Theme';
import AccountColorMarker from 'Common/AccountColorMarker.vue';

export default {
    name: 'WalletsMenu',
    components: { AccountColorMarker },
    data() {
        return {
            theme,
        };
    },
    computed: {
        ...mapGetters({
            limitSymbol: 'Accounts/activeAccountTransferLimitsSymbol',
            dailyLimit: 'Accounts/activeAccountDailyTransferLimit',
            dailySpent: 'Accounts/activeAccountDailyTransferSpent',
            dailyRemain: 'Accounts/activeAccountDailyTransferRemain',

            isActiveAccountOwned: 'Accounts/isActiveAccountOwned',
            activeAccountID: 'Accounts/activeAccountID',
            activeAccount: 'Accounts/activeAccount',
            activeAccountOwnerId: 'Accounts/activeAccountOwnerId',

            userGuid: 'Auth/userGuid',
        }),
        accountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 17px;
}
.blackText {
    font-weight: var(--fw-bold);
    font-size: var(--fs-xl);
    line-height: var(--fs-xl);
    color: var(--cl-black);
}
.accountNameRow {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: var(--m-xs);
}
.row {
    display: flex;
    align-items: center;
    column-gap: var(--m-m);
}
.mainColumn {
    padding: var(--m-xs) var(--m-m);
    border-radius: var(--m-s);
    border: 2px solid var(--cl-violet);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
</style>
