<template>
    <div :class="s.container">
        <div :class="s.column">
            <ListSelectBox
                :items="items"
                title="Placement"
                :account="activeAccount ? activeAccount.name : ''"
                v-model="activeAccountPlacementIndex"
                need-check-is-enabled
            >
                <template #item="slotProps">
                    <div :class="s.col">
                        <div :class="s.row">
                            <PlacementIcon :placement="slotProps.itemData.name" :size="14" />
                            <span>
                                {{ slotProps.itemData.name }}
                            </span>
                        </div>
                        <span :class="s.balanceText">
                            ≈{{ quotationAssetCharacter }}
                            {{ getPlacementBalances(slotProps.itemData.name).toFixed(precision).getSeparatedDigits() }}
                        </span>
                    </div>
                </template>
            </ListSelectBox>
            <slot name="balance-from"></slot>
        </div>
        <div :class="s.arrowContainer">
            <Icon :class="s.icon" :icon="isThemeDark ? 'arrow_right_big_dark' : 'arrow_right_big'" />
        </div>
        <div :class="s.column">
            <ListSelectBox
                :items="accounts"
                title="To account"
                v-model="activeAccountIndex"
                is-accounts-list
            >
                <template #item="slotProps">
                    <div>
                        <span
                            :class="s.tag"
                        >
                            <AccountColorMarker :color="getAccountColor(slotProps.itemData)" />
                            #{{ slotProps.itemData.name }}
                        </span>
                        <span :class="[s.balanceText, s.noMargin]">
                            ≈{{ quotationAssetCharacter }}{{ accountFreeBalance(slotProps.itemData.id).toFixed(balancePrecision).noExponents().getSeparatedDigits() }}
                        </span>
                    </div>
                </template>
            </ListSelectBox>
            <slot name="balance-to"></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import sortPlacementList from 'Mixins/sortPlacementList';
import Icon from 'UI/Icon.vue';
import ListSelectBox from 'Common/ListSelectBox.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import AccountColorMarker from 'Common/AccountColorMarker.vue';

export default {
    name: 'Transfer.Accounts',
    mixins: [sortPlacementList],
    components: {
        AccountColorMarker,
        Icon,
        ListSelectBox,
    },
    props: {
        fromAccountIndex: {
            type: Number,
            required: true,
        },
        toAccountIndex: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            accountsList: 'Transactions/Transfer/Accounts/accountsList',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            isThemeDark: 'isThemeDark',
            activeAccountId: 'Accounts/activeAccountID',
            balances: 'Balances/GET_BALANCES',
            activeAccount: 'Accounts/activeAccount',
        }),
        accountFreeBalance() {
            return this.$store.getters['Balances/GET_ACCOUNT_FREE_QUOTED'];
        },
        balancePrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        accounts() {
            return this.accountsList
                .filter((account) => (
                    account.policies.some((policy) => policy === 'allocation')
                    && account.ownerId === this.activeAccount.ownerId
                ));
        },
        activeAccountPlacementIndex: {
            get() {
                return this.fromAccountIndex;
            },
            set(props) {
                const { index, id } = props;
                this.$emit('update-from', { index, id });
            },
        },
        activeAccountIndex: {
            get() {
                return this.toAccountIndex;
            },
            set(props) {
                const { id, index } = props;
                this.$emit('update-to', { index, id });
            },
        },
        precision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
    },
    methods: {
        getAccountColor(account) {
            if (!account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return account.color;
        },
        getPlacementBalances(name) {
            return this.balances.reduce((accum, { accountId, placementName, quotationAssetSymbols, quotations, total }) => {
                if (placementName !== name || accountId !== this.activeAccountId) {
                    return accum;
                }
                const quotationAssetSymbolIndex = quotationAssetSymbols.findIndex((symbol) => symbol === this.quotationAssetSymbol);
                if (quotationAssetSymbolIndex === -1) {
                    return accum;
                }
                return accum + (Number(quotations[quotationAssetSymbolIndex]) * total);
            }, 0);
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: flex;
    justify-content: space-between;
    height: 217px;
    & .column {
        height: 231px;
        display: grid;
        width: 100%;
        grid-template-columns: 100%;
        grid-template-rows: 166px 44px;
        grid-row-gap: 11px;
        position: relative;
    }
    & .arrowContainer {
        min-width: 34px;
        padding: 0 var(--m-xs);
    }
    & .icon {
        width: 26px;
        height: 26px;
        margin-top: 70px;
    }
    & .tag {
        display: flex;
        align-items: center;
        background-color: var(--cl-gray-light);
        padding: var(--m-xs) var(--m-s);
        border-radius: 100px;
        column-gap: var(--m-s);
        width: max-content;
        margin-bottom: var(--m-xs);
    }
    & .row {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: var(--m-xs);
    }
    & .col {
        display: flex;
        flex-direction: column;
        row-gap: var(--m-xs);
    }
    & .balanceText {
        font-weight: var(--fw-semibold);
        font-size: var(--fs-m);
        line-height: var(--fs-m);
        color: var(--cl-gray);
        margin-left: calc(14px + var(--m-xs));
        &.noMargin {
            margin-left: 0;
        }
    }
}
</style>
