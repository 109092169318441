<template>
    <div :class="[s.container, s.legendBlock]">
        <p :class="[s.title, { [s.top]: fromAccount }]" v-if="title">
            {{ title }}
            <span
                v-if="fromAccount"
                :class="s.row"
            >
                / from
                <span :class="s.tag">
                    <AccountColorMarker :color="accountColor" />
                    #{{ trimmedFromAccount }}
                </span>
            </span>
        </p>
        <p :class="s.title" v-else>
            <slot name="title"></slot>
        </p>
        <div :class="[s.noScroll, s.content]">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AccountColorMarker from 'Common/AccountColorMarker.vue';

export default {
    name: 'LegendBlock',
    components: { AccountColorMarker },
    props: {
        title: {
            type: String,
            default: '',
        },
        fromAccount: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
        trimmedFromAccount() {
            if (this.fromAccount.length > 5) {
                return `${this.fromAccount.substring(0, 5)}...`;
            }
            return this.fromAccount;
        },
        accountColor() {
            const account = this.$store.state.Accounts.accounts.find((a) => a.name === this.fromAccount);
            if (account && account.color) {
                return account.color;
            }
            return this.isThemeDark ? '#23232A' : '#f1f2f5';
        },
    },
};
</script>

<style lang="postcss" module="s">

.legendBlock {
    border: 1px solid var(--main-border-transparent-color);
    border-radius: 3px;
    width: 100%;
    min-width: 150px;
    padding: 10px 13px 10px;
    position: relative;
    max-height: 100%;
    display: flex;

    .legend {
      position: absolute;
      top: -9px;
      left: 10px;
      padding: 0 3px;
      background: var(--main-block-color);
      color: var(--main-text-transparent-color);
      font-weight: 900;
      font-size: var(--m-text);
      line-height: 16px;
    }

    .content {
        position: relative;
        width: 100%;
    }
  }

.noScroll::-webkit-scrollbar {
    display: none;
    position: relative;
}
.container {
    background-color: var(--cl-violet-extra-light);
    border: 1px solid var(--cl-violet-light);
    width: 100%;
    min-width: 150px;
    padding: var(--m-s);
    position: relative;
    max-height: 100%;
    display: flex;
}
.content {
    width: 100%;
    overflow-y: scroll;
}
.title {
    position: absolute;
    top: -16px;
    left: -4px;
    padding: 0 var(--m-xs);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-s);
    line-height: var(--fs-s);
    color: var(--cl-gray);
    display: flex;
    align-items: flex-end;
    column-gap: var(--m-xs);
    &.top {
        top: -24px;
    }
}
.tag {
    display: flex;
    align-items: center;
    background-color: var(--cl-gray-light);
    padding: var(--m-xs) var(--m-s);
    border-radius: 100px;
    column-gap: var(--m-s);
    width: max-content;
}
.row {
    display: flex;
    align-items: flex-end;
    column-gap: var(--m-xs);
}
</style>
