<template>
    <div :class="s.relative">
        <div
            ref="walletMenu"
            :class="[s.walletMenuContainer, { [s.fixed]: !isWalletMenuVisible, [s.paddingBottom]: !isWalletMenuVisible, [s.shadow]: !isWalletMenuVisible }]"
        >
            <WalletsMenu />
        </div>
        <PageLayout>
            <template #desktop-view>
                <grid-layout
                    :layout.sync="layout"
                    :col-num="4"
                    :row-height="90"
                    :responsive="true"
                    :breakpoints="vueGridLayoutBreakpoints"
                    :cols="vueGridLayoutCols"
                    :is-draggable="true"
                    :is-resizable="false"
                    :vertical-compact="true"
                    :margin="[14, 14]"
                    :use-css-transforms="true"
                    class="desktop-layout"
                    @breakpoint-changed="breakpointChangedEvent"
                >
                    <grid-item
                        :x="getLayoutElementByName(layout, 'Transfer').x"
                        :y="getLayoutElementByName(layout, 'Transfer').y"
                        :w="getLayoutElementByName(layout, 'Transfer').w"
                        :h="getLayoutElementByName(layout, 'Transfer').h"
                        :i="getLayoutElementByName(layout, 'Transfer').i"
                        drag-allow-from=".draggable"
                    >
                        <Transfer id="transferModule" />
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'TransferHistory').x"
                        :y="getLayoutElementByName(layout, 'TransferHistory').y"
                        :w="getLayoutElementByName(layout, 'TransferHistory').w"
                        :h="getLayoutElementByName(layout, 'TransferHistory').h"
                        :i="getLayoutElementByName(layout, 'TransferHistory').i"
                        drag-allow-from=".draggable"
                    >
                        <TransferHistory id="transferHistoryModule" />
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'Payment').x"
                        :y="getLayoutElementByName(layout, 'Payment').y"
                        :w="getLayoutElementByName(layout, 'Payment').w"
                        :h="getLayoutElementByName(layout, 'Payment').h"
                        :i="getLayoutElementByName(layout, 'Payment').i"
                        drag-allow-from=".draggable"
                    >
                        <Payment id="paymentModule" />
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'AggregatedBalance').x"
                        :y="getLayoutElementByName(layout, 'AggregatedBalance').y"
                        :w="getLayoutElementByName(layout, 'AggregatedBalance').w"
                        :h="getLayoutElementByName(layout, 'AggregatedBalance').h"
                        :i="getLayoutElementByName(layout, 'AggregatedBalance').i"
                        drag-allow-from=".draggable"
                    >
                        <AgregatedBalances id="aggregatedBalancesModule" />
                    </grid-item>
                </grid-layout>
                <MultitransfersModal @confirm="confirmData = $event"/>
                <MultitransfersConfirmModal :confirm-data="confirmData"/>
            </template>
        </PageLayout>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PageLayout from 'Common/PageLayout.vue';
import { gridPages } from 'Models/interface';
import { hasManifest, getManifest } from 'Models/assetsManifest';
import layoutNameResolver from 'Mixins/layoutNameResolver';
import TransferHistory from 'Modules/TransferHistory/TransferHistory.vue';
import AgregatedBalances from 'Modules/AgregatedBalances/AgregatedBalances.vue';
import Transfer from 'Modules/Transfer/Transfer.vue';
import Payment from 'Modules/Payment/Payment.vue';
import MultitransfersModal from 'Modules/Transfer/components/MultitransfersModal.vue';
import MultitransfersConfirmModal from 'Modules/Transfer/components/MultitransfersConfirmModal.vue';

import WalletsMenu from './components/Menu.vue';

export default {
    name: 'Wallets',
    mixins: [
        layoutNameResolver,
    ],
    components: {
        MultitransfersModal,
        MultitransfersConfirmModal,
        PageLayout,
        WalletsMenu,
        Transfer,
        TransferHistory,
        Payment,
        AgregatedBalances,
    },
    data() {
        return {
            confirmData: {},
            currentBreakpoint: 'lg',
            isWalletMenuVisible: true,
            hasManifest,
            getManifest,
        };
    },
    computed: {
        ...mapGetters({
            vueGridLayoutBreakpoints: 'vueGridLayoutBreakpoints',
            vueGridLayoutCols: 'vueGridLayoutCols',
            currenciesList: 'Withdraw/GET_ASSETS',
            blockchainsList: 'Withdraw/GET_BLOCKCHAINS',
            whitelistingBlockchainsList: 'Withdraw/GET_ALL_BLOCKCHAINS',
            isThemeDark: 'isThemeDark',
        }),
        layout: {
            get() {
                return this.$store.getters.pageGrid(gridPages.WALLETS, this.currentBreakpoint);
            },
            set(grid) {
                this.$store.dispatch('changePageGrid', { page: gridPages.WALLETS, grid, breakpoint: this.currentBreakpoint });
            },
        },
    },
    methods: {
        breakpointChangedEvent(breakpoint) {
            this.currentBreakpoint = breakpoint;
        },
        scrollListener() {
            this.isWalletMenuVisible = document.querySelector('body').scrollTop < 40;
            if (!this.isWalletMenuVisible) {
                this.$refs.walletMenu.style.left = `${document.querySelector('aside').getBoundingClientRect().width + 14}px`;
            }
        },
    },
    mounted() {
        document.querySelector('body').addEventListener('scroll', this.scrollListener);
    },
    beforeDestroy() {
        document.querySelector('body').removeEventListener('scroll', this.scrollListener);
    },
};
</script>

<style lang="postcss" module="s">
.relative {
    position: relative;
}
.walletMenuContainer {
    padding: var(--m-l) 14px 0 14px;
    &.paddingBottom {
        padding-bottom: var(--m-l);
    }

    &.shadow {
        box-shadow: var(--popover-box-shadox);
    }

    &.fixed {
        position: fixed;
        top: 62px;
        right: 20px;
        z-index: 998;
        background-color: var(--cl-white-background);
        outline: 1px solid var(--cl-violet);
        border-radius: 16px;
    }
}
</style>
