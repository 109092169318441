<template>
  <div>
    <table :class="[s.table, theme.table.table, theme.transferhistory.transferhistory]">
      <thead>
        <tr>
          <th :class="[theme.table.headerText, theme.table.alignLeft]">Date</th>
          <th :class="[theme.table.headerText, theme.table.alignCenter]">Act</th>
          <th :class="[theme.table.headerText, theme.table.alignCenter]">From</th>
          <th :class="[theme.table.headerText, theme.table.alignCenter]">To</th>
          <th :class="[theme.table.headerText, theme.table.alignCenter]">Quantity</th>
          <th :class="[theme.table.headerText, theme.table.alignRight]">Status</th>
        </tr>
      </thead>
      <RecordRow v-for="record in records" :key="record.id" :record="record" />
    </table>
  </div>
</template>

<script>
import theme from 'Theme';

import RecordRow from './RecordRow.vue';

export default {
    name: 'TransferHistoryTable',
    components: {
        RecordRow,
    },
    props: {
        records: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            theme,
        };
    },
};
</script>

<style lang="postcss" module="s">
.table {
    width: 100%;
    border-collapse: collapse;
}
</style>
