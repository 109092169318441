import RawTransferRequestData, { IRawTransferRequestData } from './RawTransferRequestData';

// This file was autogenerated. Please do not change.
export interface IMultiTransferRequestData {
    totp: string;
    transfers: IRawTransferRequestData[];
}

export default class MultiTransferRequestData {
    readonly _totp: string;

    /** Description: Software TOTP code (provided by Google Auth) */
    get totp(): string {
        return this._totp;
    }

    readonly _transfers: RawTransferRequestData[];

    /** Description: List of transfers to be registered on the Platform */
    get transfers(): RawTransferRequestData[] {
        return this._transfers;
    }

    constructor(props: IMultiTransferRequestData) {
        this._totp = props.totp.trim();
        this._transfers = props.transfers.map((p) => new RawTransferRequestData(p));
    }

    serialize(): IMultiTransferRequestData {
        const data: IMultiTransferRequestData = {
            totp: this._totp,
            transfers: this._transfers.map((p) => p.serialize()),
        };
        return data;
    }

    toJSON(): IMultiTransferRequestData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            transfers: this._transfers.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
