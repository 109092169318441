
import mixins from 'vue-typed-mixins';

import TransferHistoryData from 'Modules/TransferHistory/TransferHistory.Data.vue';
import NoData from 'UI/NoData.vue';
import BlockHeader from 'UI/BlockHeader.vue';
import Pagination from 'Control/Pagination.vue';

import Table from './components/Table.vue';

interface Data {
    activePaginatorPage: number;
    activeTransferTypeIndex: number;
}

interface Computed {
    activeAccountId: string;
    pagesCount: number;
}

interface Methods {
    onSelect: (data: { index: number }) => void;
}

export default mixins(TransferHistoryData).extend<Data, Methods, Computed, any>({
    name: 'TransfersHistory',
    components: {
        Table,
        NoData,
        Pagination,
        BlockHeader,
    },
    mixins: [TransferHistoryData],
    data() {
        return {
            activePaginatorPage: 1,
            activeTransferTypeIndex: 0,
        };
    },
    computed: {
        activeAccountId() {
            return this.$store.state.Accounts.activeAccountID;
        },
        pagesCount() {
            return this.transferHistoryUi.pages;
        },
    },
    methods: {
        onSelect({ index }) {
            this.activePaginatorPage = 1;
            this.activeTransferTypeIndex = index;
        },
    },
    watch: {
        async activeAccountId() {
            if (this.activePaginatorPage && this.activeTransferTypeIndex !== null && this.activeTransferTypeIndex !== undefined) {
                await this.getTransferHistory({
                    page: this.activePaginatorPage,
                    transferType: this.activeTransferTypeIndex,
                });
                this.activePaginatorPage = 1;
            }
        },
        async activePaginatorPage() {
            if (this.activePaginatorPage && this.activeTransferTypeIndex !== null && this.activeTransferTypeIndex !== undefined) {
                await this.getTransferHistory({
                    page: this.activePaginatorPage,
                    transferType: this.activeTransferTypeIndex,
                });
            }
        },
        async activeTransferTypeIndex() {
            if (this.activePaginatorPage && this.activeTransferTypeIndex !== null && this.activeTransferTypeIndex !== undefined) {
                await this.getTransferHistory({
                    page: this.activePaginatorPage,
                    transferType: this.activeTransferTypeIndex,
                });
            }
        },
    },
    async created() {
        if (this.activePaginatorPage && this.activeTransferTypeIndex !== null && this.activeTransferTypeIndex !== undefined) {
            await this.getTransferHistory({
                page: this.activePaginatorPage,
                transferType: this.activeTransferTypeIndex,
            });
        }
    },
});
