<template>
    <tbody @click="openDepositModal">
    <tr @click="toggleAdditionalData">
        <td :class="[theme.transferhistory.grayText, theme.table.alignLeft]">
            {{ $store.getters.getTimeDateString({ timestamp: record.createdAt }) }}
        </td>
        <td :class="[theme.transferhistory.blackText, theme.table.alignCenter, theme.transferhistory.capitalize]">
            {{ record.type }}
        </td>
        <RecordFrom :record="record" :is-accounts-transfer="isAccountsTransfer" />
        <RecordTo :record="record" :is-accounts-transfer="isAccountsTransfer" />
        <td>
            <AssetValue
                :value="record.quantity.getSeparatedDigits()"
                :asset="currencySymbol"
                :need-to-round="false"
                :value-class="theme.transferhistory.boldText"
                is-aligned-center
            />
        </td>
        <td
            :class="[theme.transferhistory.statusText, theme.table.alignRight, theme.transferhistory[TransferStatuses[record.status].color]]"
        >
            {{ TransferStatuses[record.status].layoutName }}
            <template>
                <Icon icon="arrow_right" :class="[{ [s.rotated]: showAdditionalData }, s.icon]" />
            </template>
        </td>
    </tr>
    <template v-if="showAdditionalData">
        <tr :class="[s.additionalRow, theme.transferhistory.details]">
            <td :class="[theme.transferhistory.header, s.top]">
                <span>Details</span>
            </td>
            <td colspan="3" :class="s.top">
                <div :class="[s.row, s.mbS]">
                    <span :class="[theme.transferhistory.subheader, s.mrS]">Transfer ID:</span>
                    <Guid
                        :guid="record.id"
                        copied-value-name="Transfer ID"
                    />
                </div>
                <div v-if="(record.type === 'withdraw' || record.type === 'deposit') && record.blockchainTxId" :class="[s.row, s.mbS]">
                    <span :class="[theme.transferhistory.subheader, s.mrS]">Blockchain TxId:</span>
                    <Guid
                        is-short
                        :guid="record.blockchainTxId"
                        copied-value-name="Network TX ID"
                    />
                </div>
                <div :class="[s.row, s.mbS]">
                    <span :class="[theme.transferhistory.subheader, s.mrS]">Registered At:</span>
                    <span :class="theme.transferhistory.content">{{ $store.getters.getTimeDateString({ timestamp: record.createdAt }) }}</span>
                </div>
                <div :class="[s.row, s.mbS]">
                    <span :class="[theme.transferhistory.subheader, s.mrS]">Currency:</span>
                    <div :class="[s.row, theme.transferhistory.content]">
                        <CryptoIcon :class="s.mrS" :symbol="currencySymbol" :size="14" />
                        {{ currencySymbol }}
                    </div>
                </div>
                <div :class="s.row">
                    <span :class="[theme.transferhistory.subheader, s.mrS]">Quantity:</span>
                    <AssetValue
                        :value="record.quantity.getSeparatedDigits()"
                        :asset="currencySymbol"
                        :need-to-round="false"
                        :value-class="theme.transferhistory.content"
                    />
                </div>
            </td>
            <td colspan="2" :class="s.top">
                <div v-if="isAccountsTransfer" :class="[s.row]">
                    <span :class="[theme.transferhistory.subheader, s.mrS]">Placement:</span>
                    <PlacementIcon :class="s.mrS" :placement="record.fromPlacementName" />
                    <span :class="[theme.transferhistory.content, theme.transferhistory.big]">
                        {{ record.fromPlacementName.toUpperCase() }}
                    </span>
                </div>
                <div :class="[s.row]">
                    <span :class="[theme.transferhistory.subheader, s.mrS]">From:</span>
                    <RecordFrom
                        :record="record"
                        :is-accounts-transfer="isAccountsTransfer"
                        need-guid
                        is-block
                    />
                </div>
                <div :class="s.row">
                    <span :class="[theme.transferhistory.subheader, s.mrS]">To:</span>
                    <RecordTo
                        :record="record"
                        :is-accounts-transfer="isAccountsTransfer"
                        need-guid
                        is-block
                    />
                </div>
                <div :class="s.row" v-if="record.commission.length">
                    <span :class="[theme.transferhistory.subheader, s.mrS]">Fees:</span>
                    <div :class="s.feesRow">
                        <AssetValue
                            v-for="commission in record.commission"
                            :key="commission.assetSymbol"
                            :value="commission.value.toFixed(8).noExponents().getSeparatedDigits()"
                            :asset="commission.assetSymbol"
                            :need-to-round="false"
                            :value-class="theme.transferhistory.content"
                        />
                    </div>
                </div>
                <div
                    v-if="TransferStatuses[record.status].needAlert"
                    :class="s.row"
                >
                    <div
                        :class="s.alertCapsule"
                        @click="openBugReport"
                    >
                        <Icon
                            icon="alert"
                            :class="s.alertIcon"
                        />
                        <span :class="theme.transferhistory.blackText">
                            Please contact support
                        </span>
                    </div>
                </div>
            </td>
        </tr>
    </template>
    </tbody>
</template>

<script>
import { RecordsTypes, RecordStatuses } from 'Models/transfersHistory';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import Icon from 'UI/Icon.vue';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Guid from 'UI/Guid.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import PublicDataApi from 'Apis/PublicData';
import AssetRequest from 'Entities/publicPresenter/AssetRequest';
import { TransferStatuses } from 'Store/v2/Transfer';

import RecordTo from './RecordTo.vue';
import RecordFrom from './RecordFrom.vue';

const DetailsRowTypes = {
    ID: 'id',
};

export default {
    name: 'TransferHistoryRecordRow',
    components: {
        RecordFrom,
        RecordTo,
        Icon,
        AssetValue,
        Guid,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            RecordsTypes,
            RecordStatuses,
            DetailsRowTypes,
            showAdditionalData: false,
            theme,
            TransferStatuses,
        };
    },
    computed: {
        currencySymbol() {
            return this.record.assetSymbol;
        },
        isAccountsTransfer() {
            return this.record.type === RecordsTypes.TRANSFER && this.record.fromAccountId !== this.record.toAccountId;
        },
        details() {
            const result = [
                {
                    label: 'TRANSFER_ID',
                    value: this.record.id,
                    type: DetailsRowTypes.ID,
                },
                {
                    label: 'REGISTERED_AT',
                    value: this.$store.getters.getTimeDateString({ timestamp: this.record.createdAt, showSeconds: true }),
                },
                {
                    label: 'QUANTITY',
                    value: `${this.record.quantity.getSeparatedDigits()} ${this.currencySymbol}`,
                },
            ];

            if (!this.isAccountsTransfer) {
                if (+this.record.commission.blockchainFee.value > 0) {
                    result.push({
                        label: 'BLOCKCHAIN_FEE',
                        value: `${this.record.commission.blockchainFee.value.getSeparatedDigits()} ${this.currencySymbol}`,
                    });
                }

                if (+this.record.commission.excelsiorFee.value > 0) {
                    result.push({
                        label: 'SINGLE_BROKER_FEE',
                        value: `${this.record.commission.excelsiorFee.value.getSeparatedDigits()} ${this.currencySymbol}`,
                    });
                }

                if (+this.record.commission.transferSourceFee.value > 0) {
                    result.push({
                        label: 'TRANSFER_SOURCE_FEE',
                        value: `${this.record.commission.transferSourceFee.value.getSeparatedDigits()} ${this.currencySymbol}`,
                    });
                }

                result.push(
                    {
                        label: 'FROM',
                        value: this.record.type === RecordsTypes.DEPOSIT ? 'User' : this.record.fromPlacementName,
                    },
                    {
                        label: 'TO',
                        value: this.record.type === RecordsTypes.WITHDRAW ? 'User' : this.record.toPlacementName,
                    },
                );
            } else {
                result.push(
                    {
                        label: 'PLACEMENT',
                        value: this.record.fromPlacementName,
                    },
                    {
                        label: 'FROM_ACCOUNT_ID',
                        value: this.record.fromAccountId,
                        type: DetailsRowTypes.ID,
                    },
                    {
                        label: 'TO_ACCOUNT_ID',
                        value: this.record.toAccountId,
                        type: DetailsRowTypes.ID,
                    },
                );
            }

            if (this.record.type === RecordsTypes.DEPOSIT || this.record.type === RecordsTypes.WITHDRAW) {
                if (this.record.blockchainTxId) {
                    result.push({
                        label: 'TRANSACTION',
                        value: `${this.record.blockchainTxId.substr(0, 20)}...`,
                        isLink: true,
                        linkHref: '',
                        isClickable: true,
                        onClick: async () => {
                            await copyTextWithPermissions(this.record.blockchainTxId, this.$store.dispatch, 'Network TX ID');
                        },
                    });
                }
            }

            if (this.record.type === RecordsTypes.WITHDRAW) {
                result.push(
                    {
                        label: 'TO_ADDRESS',
                        value: `${this.record.address.substr(0, 20)}...`,
                        isLink: true,
                        linkHref: '',
                        isClickable: true,
                        onClick: async () => {
                            await copyTextWithPermissions(this.record.address, this.$store.dispatch, 'Address');
                        },
                    },
                    {
                        label: 'MEMO',
                        value: this.record.memo,
                    },
                );
            }

            if (this.record.status === RecordStatuses.FAILED) {
                result.push({
                    label: 'FAILURE_REASON',
                    value: this.record.failureReason,
                });
            }

            return result;
        },
    },
    methods: {
        openBugReport() {
            window.open('https://www.singlebroker.com/bug-report');
        },
        toggleAdditionalData() {
            this.showAdditionalData = !this.showAdditionalData;
        },
        async openDepositModal() {
            if (this.record.type === 'deposit' && this.record.status === 'created') {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    const { data: asset } = await PublicDataApi.publicGetAsset(new AssetRequest({
                        symbol: this.record.assetSymbol,
                    }));
                    if (asset.type === 'fiat') {
                        await this.$router.push({
                            path: '/Wallets/Confirmations/CompleteFiatDepositHistory',
                            query: {
                                ...this.record.serialize(),
                                previousRoute: this.$router.currentRoute.path,
                            },
                        });
                    } else {
                        await this.$router.push({
                            path: '/Wallets/Confirmations/CompleteDepositHistory',
                            query: {
                                ...this.record.serialize(),
                                previousRoute: this.$router.currentRoute.path,
                            },
                        });
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.icon {
    color: var(--cl-black);
    transition: transform var(--transition);
    &.rotated {
        transform: rotate(90deg);
    }
}
.middle {
    vertical-align: middle;
}
.top {
    vertical-align: top;
}
.additionalRow {
    background-color: var(--cl-expand);
}
.row {
    display: flex;
    align-items: center;
    &.mbS {
        margin-bottom: var(--m-m);
    }
    & .mrS {
        margin-right: var(--m-s);
    }
}
.feesRow {
    display: flex;
    flex-direction: column;
    column-gap: var(--m-s);
    row-gap: var(--m-s);
}
.alertCapsule {
    background-color: var(--cl-gray-light);
    border-radius: 100px;
    padding: var(--m-xs) var(--m-s);
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
    cursor: pointer;
    margin-top: var(--m-s);

    & .alertIcon {
        color: var(--cl-red);
        height: 14px;
        width: 14px;
    }
}
</style>
