import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
        }),
        isSorted() {
            return !!this.activeAccount && !!this.activeAccount.placementLinks.length;
        },
        items() {
            const placementsList = this.$store.getters['Transactions/Transfer/placementsList'];

            if (this.activeAccount && this.activeAccount.placementLinks.length) {
                return [...placementsList
                    .map((item, initialIndex) => {
                        const isExist = this.activeAccount.placementLinks.find((l) => l === item.name);
                        if (isExist
                            && (this.$store.state.Placements.maintenanceStatuses.get(item.name) || item.name === 'Single Broker')
                            && (this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](item.name) || item.name === 'Single Broker')) {
                            return {
                                ...item,
                                initialIndex,
                                isBlocked: false,
                                isEnabled: true,
                                isPlacementLinked: true,
                            };
                        }

                        if (!this.$store.state.Placements.maintenanceStatuses.get(item.name) && item.name !== 'Single Broker') {
                            return {
                                ...item,
                                initialIndex,
                                isBlocked: false,
                                isEnabled: false,
                                isPlacementLinked: true,
                            };
                        }

                        if (!this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](item.name) && item.name !== 'Single Broker' && !item.disabled) {
                            return {
                                ...item,
                                initialIndex,
                                isBlocked: false,
                                isEnabled: true,
                                isPlacementLinked: false,
                            };
                        }

                        return {
                            ...item,
                            initialIndex,
                            isBlocked: true,
                            isEnabled: true,
                            isPlacementLinked: true,
                        };
                    })
                    .sort((a, b) => {
                        if (a.type === 'crypto-spot' && b.type !== 'crypto-spot') {
                            return -1;
                        }
                        if (b.type === 'crypto-spot' && a.type !== 'crypto-spot') {
                            return 1;
                        }
                        return 0;
                    })
                    // eslint-disable-next-line no-nested-ternary
                    .sort((a, b) => (a.name === 'Single Broker' ? -1 : b.name === 'Single Broker' ? 1 : 0))
                    .sort((a, b) => a.isBlocked - b.isBlocked)
                    .sort((a, b) => a.disabled - b.disabled)
                    .filter((p) => p.type !== 'crypto-spot-decentralized')
                    .filter(({ isBlocked }) => !isBlocked),
                ];
            }
            return placementsList
                .filter((p) => {
                    return p.type !== 'crypto-spot-decentralized' && !p.disabled;
                })
                .map((p, initialIndex) => {
                    if (!this.$store.state.Placements.maintenanceStatuses.get(p.name) && p.name !== 'Single Broker') {
                        return {
                            ...p,
                            initialIndex,
                            isBlocked: false,
                            isEnabled: false,
                            isPlacementLinked: false,
                        };
                    }

                    p.isBlocked = false;
                    p.isEnabled = true;
                    p.isPlacementLinked = false;
                    return p;
                });
        },
    } as any,
};
