
import Vue from 'vue';
import { mapGetters } from 'vuex';

import EmptyResult from 'Entities/walletExecutor/EmptyResult';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import Account from 'Entities/privatePresenter/Account';
import theme from 'Theme';
import Checkbox from 'Control/Checkbox.vue';
import Button from 'Control/Button.vue';
import WalletsApi from 'Apis/Wallets';
import MultiTransferRequestData, { IMultiTransferRequestData } from 'Entities/walletExecutor/MultiTransferRequestData';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import ApiError from 'Entities/ApiError';
import { IRawTransferRequestData } from 'Entities/walletExecutor/RawTransferRequestData';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';

interface Props {
    confirmData: {
        toPlacements: { name: string; quantity: number; feeSize: null | string; fees: Record<string, unknown> | EmptyResult | undefined; checked: boolean; }[];
        totalQuantity: number;
        totalFees: { asset: string; quantity: number; rate: number; }[];
        totalFeesQuotation: number;
        totalQuantityQuotation: number;
        fromPlacement: string;
        asset: string;
        blockchain: string;
        variant: number;
        quantityRate: number;
    };
}

interface Data {
    theme: typeof theme;
    toPlacements: { name: string; quantity: number; feeSize: null | string; fees: Record<string, unknown> | EmptyResult | undefined; checked: boolean; }[];
    totalQuantity: number;
    totalQuantityQuotation: number;
    quantityRate: number;
    totalFees: { asset: string; quantity: number; rate: number; }[];
    totalFeesQuotation: number;
}

interface Methods {
    onShow: () => void;
    onChange: (data: number) => void;
    back: () => void;
    confirm: () => void;
}

interface Computed {
    activeAccount: Account;
    isThemeDark: boolean;
    activeAccountColor: string;
    quotationAssetCharacter: string;
    isConfirmButtonDisabled: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: {
        AccountColorMarker,
        Checkbox,
        Button,
    },
    props: {
        confirmData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            theme,
            toPlacements: [],
            totalQuantity: 0,
            totalQuantityQuotation: 0,
            quantityRate: 0,
            totalFees: [],
            totalFeesQuotation: 0,
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
        }),
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        isConfirmButtonDisabled() {
            return !this.toPlacements.some(({ checked }) => checked);
        },
    },
    methods: {
        onShow() {
            this.toPlacements = this.confirmData.toPlacements;
            this.totalQuantity = this.confirmData.totalQuantity;
            this.totalQuantityQuotation = this.confirmData.totalQuantityQuotation;
            this.quantityRate = this.confirmData.quantityRate;
            this.totalFees = this.confirmData.totalFees;
            this.totalFeesQuotation = this.confirmData.totalFeesQuotation;
        },
        onChange(index) {
            this.toPlacements = this.toPlacements.map((p, i) => {
                if (i === index) {
                    if (p.checked) {
                        this.totalQuantity -= p.quantity;
                        this.totalQuantityQuotation -= p.quantity * this.quantityRate;
                        if (p.feeSize && p.fees) {
                            const index = this.totalFees.findIndex(({ asset }) => asset === p.fees![p.feeSize!].assetSymbol);
                            this.totalFees[index].quantity -= p.fees[p.feeSize].amount;
                            this.totalFeesQuotation -= p.fees[p.feeSize].amount * this.totalFees[index].rate;
                        }
                    } else {
                        this.totalQuantity += p.quantity;
                        this.totalQuantityQuotation += p.quantity * this.quantityRate;
                        if (p.feeSize && p.fees) {
                            const index = this.totalFees.findIndex(({ asset }) => asset === p.fees![p.feeSize!].assetSymbol);
                            this.totalFees[index].quantity += p.fees[p.feeSize].amount;
                            this.totalFeesQuotation += p.fees[p.feeSize].amount * this.totalFees[index].rate;
                        }
                    }
                    return {
                        ...p,
                        checked: !p.checked,
                    };
                }
                return p;
            });
        },
        back() {
            this.$modal.hide('multitransfersConfirmModal');
            this.$modal.show('multitransfersModal');
        },
        async confirm() {
            const previousRoute = this.$router.currentRoute.path;
            try {
                const totp = await this.$store.dispatch('Auth/getMFAToken', { type: MFA_ENROLL_FACTOR_TYPES.TOTP, previousRoute });
                this.$store.commit(SET_LOADING_ON(undefined));
                const request: IMultiTransferRequestData = {
                    totp,
                    transfers: [...this.toPlacements
                        .reduce((result, current) => {
                            if (current.checked) {
                                result.push({
                                    amount: current.quantity,
                                    assetSymbol: this.confirmData.asset,
                                    blockchainName: this.confirmData.blockchain,
                                    feeSize: current.feeSize as 'low' | 'medium' | 'high' | undefined ?? undefined,
                                    source: {
                                        accountId: this.activeAccount.id,
                                        placementName: this.confirmData.variant === 0 ? this.confirmData.fromPlacement : current.name,
                                    },
                                    destination: {
                                        accountId: this.activeAccount.id,
                                        placementName: this.confirmData.variant === 0 ? current.name : this.confirmData.fromPlacement,
                                    },
                                });
                            }
                            return result;
                        }, [] as IRawTransferRequestData[])],
                };
                await WalletsApi.transferMulti(new MultiTransferRequestData(request));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Transfers successfully performed');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during performing transfers');
                }
            } finally {
                await this.$router.push(previousRoute);
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
    },
});
